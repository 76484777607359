export default `:host {
	display: block;
	text-align: initial;
	background-color: #fafafa;
	color: #343434;
	line-height: 1.3;
	border: 1px solid #dadada;
	border-radius: 6px;
	max-width: 100%;
	box-sizing: border-box;
	overflow: auto;
	padding: 4px 12px;
	color-scheme: light dark;
}

:host([theme="light"]) {
	color-scheme: light;
}

:host([theme="dark"]) {
	color-scheme: dark;
}

* {
	box-sizing: border-box;
}

.event:not(:last-of-type) {
	border-bottom: 1px solid currentColor;
	margin-bottom: 1.1em;
}

.event-section:not(:last-of-type) {
	margin-bottom: 6px;
}

.event-name {
	text-align: center;
	text-decoration: underline;
	padding: 0;
	margin: 0.2em;
}

.event-url {
	color: inherit;
	display: block;
	cursor: pointer;
	text-decoration: none;
}

.event-description {
	display: inline-block;
	max-width: calc(100% - 1.8em);
	margin: 0 0 0 0.3em;
}

.center {
	text-align: center;
}

.icon {
	width: 1em;
	height: 1em;
	vertical-align: middle;
}

.float-left {
	float: left;
}

.app-link {
	color: inherit;
	text-decoration: none;
	display: block;
}

.clearfix::after {
	content: "";
	display: block;
	clear: both;
}

:host([theme="dark"]) {
	background-color: #212121;
	color: #fefefe;
}

@media (prefers-color-scheme: dark) {
	:host(:not([theme="light"])) {
		background-color: #212121;
		color: #fefefe;
		border-color: #cacaca;
	}
}`;
